import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import Convert from 'color-convert';
import { RiCreativeCommonsLine, RiCreativeCommonsByLine, RiCreativeCommonsNcLine, RiCreativeCommonsSaLine } from 'react-icons/ri';

import { ReactComponent as Logo } from './../assets/img/logo.svg';

class App extends Component{
  constructor(){
    super();
    
    this.state = {
      date: {
        day: 0,
        dayLong: '',
        month: 0,
        monthLong: '',
        year: 0,
        yearLong: ''
      },
      color: ''
    }

    this.copy = 2024;
    this.today = new Date();

    this.interval = '';
    
    this.addZero = this.addZero.bind(this);
    this.getTime = this.getTime.bind(this);
  }

  componentDidMount(){
    this.getTime();
    // this.interval = setInterval(() => this.getTime(), 1000);
  }

  componentDidUpdate(prevProps, prevState){
    // if(this.state.search !== prevState.search){}
  }

  componentWillUnmount(){
    clearInterval(this.interval);
  }

  addZero(value){
    value = String(value);

    if(value.length === 1){
      value = '0' + value;
    }

    return value;
  }

  getTime(){
    const date = new Date(),
          hou = this.addZero(date.getDate()),
          dayLong = date.toLocaleDateString('default', { weekday: 'long' }),
          min = this.addZero(date.getMonth() + 1),
          monthLong = date.toLocaleString('default', { month: 'long' }),
          yearLong = date.getFullYear(),
          sec = this.addZero(((yearLong.toString()).substring(2, 4))),
          hex = hou + min + sec,
          rgb = Convert.hex.rgb(hex),
          rgbRed = parseFloat(((rgb[0] / 255) * 100).toFixed(0)),
          rgbGreen = parseFloat(((rgb[1] / 255) * 100).toFixed(0)),
          rgbBlue = parseFloat(((rgb[2] / 255) * 100).toFixed(0)),
          hsl = Convert.hex.hsl(hex),
          cmyk = Convert.hex.cmyk(hex),
          cmykCyan = parseFloat(((cmyk[0] / 255) * 100).toFixed(0)),
          cmykMagenta = parseFloat(((cmyk[1] / 255) * 100).toFixed(0)),
          cmykYellow = parseFloat(((cmyk[2] / 255) * 100).toFixed(0)),
          cmykBlack = parseFloat(((cmyk[3] / 255) * 100).toFixed(0));
          
    this.setState({
      date: {
        day: hou,
        dayLong: dayLong,
        month: min,
        monthLong: monthLong,
        year: sec,
        yearLong: yearLong
      },
      color: {
        hex: hex,
        ansi16: Convert.hex.ansi16(hex),
        ansi256: Convert.hex.ansi256(hex),
        apple: Convert.hex.apple(hex),
        cmyk: cmyk,
        cyan: cmykCyan,
        magenta: cmykMagenta,
        yellow: cmykYellow,
        black: cmykBlack,
        totalCmyk: cmykCyan + cmykMagenta + cmykYellow + cmykBlack,
        gray: Convert.hex.gray(hex),
        hcg: Convert.hex.hcg(hex),
        hsl: hsl,
        hsv: Convert.hex.hsv(hex),
        hwb: Convert.hex.hwb(hex),
        keyword: Convert.hex.keyword(hex),
        lab: Convert.hex.lab(hex),
        lch: Convert.hex.lch(hex),
        rgb: rgb,
        xyz: Convert.hex.xyz(hex),
        red: rgbRed,
        green: rgbGreen,
        blue: rgbBlue,
        totalRgb: rgbRed + rgbGreen + rgbBlue,
        hue: ((hsl[0] / 360) * 100).toFixed(0),
        bright: 100 - cmyk[3]
      }
    });
  }

  renderColor(){
    if(this.state.color){
      return(
        <div className="clbg" style={{ background: '#' + this.state.color.hex }}>
          <div className="cltx">
            {this.state.date.day}/{this.state.date.month}/{this.state.date.year}
            <div>{this.state.date.dayLong}, {this.state.date.day} {this.state.date.monthLong} {this.state.date.yearLong}</div>
            <strong>
              #{this.state.color.hex}<br />
              rgb({this.state.color.rgb[0]}, {this.state.color.rgb[1]}, {this.state.color.rgb[2]})
            </strong>
            <ul>
              <li>
                <span>ANSI-16</span>
                {this.state.color.ansi16}
              </li>
              <li>
                <span>ANSI-256</span>
                {this.state.color.ansi256}
              </li>
              <li>
                <span>Apple</span>
                apple({this.state.color.apple[0]}, {this.state.color.apple[1]}, {this.state.color.apple[2]})
              </li>
              <li>
                <span>Closest CSS Keyword</span>
                <div className="colorname" style={{ background: this.state.color.keyword }}></div>{this.state.color.keyword}
              </li>
              <li>
                <span>CMYK</span>
                cmyk({this.state.color.cmyk[0]}, {this.state.color.cmyk[1]}, {this.state.color.cmyk[2]}, {this.state.color.cmyk[3]})
              </li>
              <li>
                <span>Gray</span>
                {this.state.color.gray[0]}
              </li>
              <li>
                <span>HCG</span>
                hcg({this.state.color.hcg[0]}, {this.state.color.hcg[1]}, {this.state.color.hcg[2]})
              </li>
              <li>
                <span>HSL</span>
                hsl({this.state.color.hsl[0]}, {this.state.color.hsl[1]}, {this.state.color.hsl[2]})
              </li>
              <li>
                <span>HSV</span>
                hsv({this.state.color.hsv[0]}, {this.state.color.hsv[1]}, {this.state.color.hsv[2]})
              </li>
              <li>
                <span>HWB</span>
                hwb({this.state.color.hwb[0]}, {this.state.color.hwb[1]}, {this.state.color.hwb[2]})
              </li>
              <li>
                <span>LAB</span>
                lab({this.state.color.lab[0]}, {this.state.color.lab[1]}, {this.state.color.lab[2]})
              </li>
              <li>
                <span>LCH</span>
                lch({this.state.color.lch[0]}, {this.state.color.lch[1]}, {this.state.color.lch[2]})
              </li>
              <li>
                <span>XYZ</span>
                xyz({this.state.color.xyz[0]}, {this.state.color.xyz[1]}, {this.state.color.xyz[2]})
              </li>
              <li className="lay2 clear same">
                <span>Hue <strong>{this.state.color.hue}%</strong></span>
                <div className="chart">
                  <span className="huepicker">
                    <div className="pointer" style={{ left: this.state.color.hue + '%' }}></div>
                  </span>
                </div>
              </li>
              <li className="lay2 same">
                <span>Brightness <strong>{this.state.color.bright}%</strong></span>
                <div className="chart">
                  <span className="darkpicker">
                    <div className="pointer" style={{ left: this.state.color.bright + '%' }}></div>
                  </span>
                </div>
              </li>
              <li className="lay2">
                <ul>
                  <li className="lay3">
                    <span>RGB - Red <strong>{this.state.color.red}%</strong></span>
                    <div className="chart">
                      <span style={{ background: 'red', width: this.state.color.red + '%' }}></span>
                    </div>
                  </li>
                  <li className="lay3">
                    <span>RGB - Green <strong>{this.state.color.green}%</strong></span>
                    <div className="chart">
                      <span style={{ background: 'green', width: this.state.color.green + '%' }}></span>
                    </div>
                  </li>
                  <li className="lay3">
                    <span>RGB - Blue <strong>{this.state.color.blue}%</strong></span>
                    <div className="chart">
                      <span style={{ background: 'blue', width: this.state.color.blue + '%' }}></span>
                    </div>
                  </li>
                  <li className="layfull">
                    <span>RGB - Scheme</span>
                    <div className="chart multi">
                      <span style={{ background: 'red', width: ((this.state.color.red / this.state.color.totalRgb) * 100) + '%' }}></span>
                      <span style={{ background: 'green', width: ((this.state.color.green / this.state.color.totalRgb) * 100) + '%' }}></span>
                      <span style={{ background: 'blue', width: ((this.state.color.blue / this.state.color.totalRgb) * 100) + '%' }}></span>
                    </div>
                  </li>
                </ul>
              </li>
              <li className="lay2">
                <ul>
                  <li className="lay4">
                    <span>CMYK - Cyan <strong>{this.state.color.cmyk[0]}%</strong></span>
                    <div className="chart">
                      <span style={{ background: 'cyan', width: this.state.color.cyan + '%' }}></span>
                    </div>
                  </li>
                  <li className="lay4">
                    <span>CMYK - Magenta <strong>{this.state.color.cmyk[1]}%</strong></span>
                    <div className="chart">
                      <span style={{ background: 'magenta', width: this.state.color.magenta + '%' }}></span>
                    </div>
                  </li>
                  <li className="lay4">
                    <span>CMYK - Yellow <strong>{this.state.color.cmyk[2]}%</strong></span>
                    <div className="chart">
                      <span style={{ background: 'yellow', width: this.state.color.yellow + '%' }}></span>
                    </div>
                  </li>
                  <li className="lay4">
                    <span>CMYK - Black <strong>{this.state.color.cmyk[3]}%</strong></span>
                    <div className="chart">
                      <span style={{ background: 'black', width: this.state.color.black + '%' }}></span>
                    </div>
                  </li>
                  <li className="layfull">
                    <span>CMYK - Scheme</span>
                    <div className="chart multi">
                      <span style={{ background: 'cyan', width: ((this.state.color.cyan / this.state.color.totalCmyk) * 100) + '%' }}></span>
                      <span style={{ background: 'magenta', width: ((this.state.color.magenta / this.state.color.totalCmyk) * 100) + '%' }}></span>
                      <span style={{ background: 'yellow', width: ((this.state.color.yellow / this.state.color.totalCmyk) * 100) + '%' }}></span>
                      <span style={{ background: 'black', width: ((this.state.color.black / this.state.color.totalCmyk) * 100) + '%' }}></span>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      );
    }else{
      return('');
    }
  }

  render(){
    return(
      <>
        <Helmet>
          <title>HexDate {`${this.state.date.day}/${this.state.date.month}/${this.state.date.year}`}</title>
        </Helmet>
        <header>
          <a href="/">
            <Logo />
          </a>
        </header>
        {this.renderColor()}
        <footer>
          {/* Copyrights &copy; {this.today.getFullYear() !== this.copy ? this.copy + ' - ' + this.today.getFullYear() : this.copy} <a href="/">HexDate</a> by <a href="https://nabilamerthabit.com" target="_blank" rel="noreferrer">Nabil Amer Thabit</a>. All Rights Reserved. */}
          <a href="/">HexDate</a> &copy; {this.today.getFullYear() !== this.copy ? this.copy + ' - ' + this.today.getFullYear() : this.copy} by <a href="https://nabilamerthabit.com" target="_blank" rel="noreferrer">Nabil Amer Thabit</a> is licensed under <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/?ref=chooser-v1" target="_blank" rel="license noopener noreferrer">CreativeCommons BY-NC-SA 4.0 <RiCreativeCommonsLine /> <RiCreativeCommonsByLine /> <RiCreativeCommonsNcLine /> <RiCreativeCommonsSaLine /></a>
        </footer>
      </>
    );
  }
}

export default App;